import styled from "@emotion/styled";

import {
  MigrationSetError,
  PageBox,
  Pill,
  Scheme,
  StandardButton,
  Table,
} from "../../../components";
import { COLOURS, MigrationSetWithImports } from "../../../shared";
import { useMemo } from "react";
import { ColumnDef } from "@tanstack/react-table";

const OpenViewButton = styled(StandardButton)<{ status: string }>`
  color: ${COLOURS.openViewButtonColor};
  border: 1px solid
    ${(props) =>
      props.status === "Open Import"
        ? COLOURS.openButtonBorder
        : COLOURS.viewButtonBorder};
  background: ${(props) =>
    props.status === "Open Import"
      ? COLOURS.openButtonBackground
      : COLOURS.viewButtonBackground};
  width: 14.1rem;
`;

type ExistingMigrationProps = {
  data: MigrationSetWithImports[];
  selectMigration: (id: number, status: string) => void;
  showNewImportButton?: boolean;
};

const formatUTCDateForLocale = (
  utcDateString: Date | null,
  accountBillingCountryCode: string,
): string => {
  if (!utcDateString) {
    return "";
  }

  const utcDate = new Date(utcDateString);

  const countryLocaleMap: Record<string, string> = {
    GB: "en-GB",
    AU: "en-AU",
    US: "en-US",
  };

  const locale = countryLocaleMap[accountBillingCountryCode] || "en-US";

  const formattedDate = utcDate.toLocaleDateString(locale, {
    day: "2-digit",
    month: "2-digit",
    year: "numeric",
  });

  const formattedTime = utcDate.toLocaleTimeString(locale, {
    hour: "numeric",
    minute: "numeric",
    hour12: true,
  });

  return `${formattedDate} - ${formattedTime}`;
};

const ExistingMigration = ({
  data,
  selectMigration,
}: ExistingMigrationProps) => {
  // Precompute totalRecordCountForLoad for each migrationSet in data
  const totalRecordCountForLoadMap = useMemo(() => {
    return data.reduce((acc, migrationSet) => {
      acc[migrationSet.migrationSetId] = migrationSet.imports.reduce(
        (sum, imp) => sum + imp.totalRecordCountForLoad,
        0
      );
      return acc;
    }, {} as Record<number, number>);
  }, [data]);

  // Calculate loading percentage for each migrationSet using precomputed totalRecordCountForLoadMap
  const calculateLoadingPercentage = (migrationSet: MigrationSetWithImports) => {
    const totalRecordCountForLoad = totalRecordCountForLoadMap[migrationSet.migrationSetId] || 0;

    // Calculate only totalRecordsLoaded, since totalRecordCountForLoad is precomputed
    const totalRecordsLoaded = migrationSet.imports.reduce(
      (sum, imp) => sum + (imp.loadTotalRecordCount + imp.loadErrorCount), // Errors are not included in the load total record and see need to be summed together
      0, // The initial value for the sum is set to 0.
    );

    // Calculate the percentage of loaded records relative to uploaded records.
    // If `totalRecordCountForLoad` is greater than 0, calculate the percentage (totalRecordsLoaded / totalRecordCountForLoad) * 100.
    // If `totalRecordCountForLoad` is 0 (to avoid division by 0), return 0%.
    const percentage =
    totalRecordCountForLoad > 0 ? (totalRecordsLoaded / totalRecordCountForLoad) * 100 : 0;

    // Cap percentage at 99% to have the UI show 99% to loading and then move to completed status
    return Math.min(Math.round(percentage), 99);
  };

  const cols = useMemo<ColumnDef<MigrationSetWithImports>[]>(
    () => [
      {
        header: "#",
        accessorKey: "migrationSetNbr",
        size: 4,
      },
      {
        header: "Submitted on",
        accessorKey: "utcSubmittedAt",
        sortType: "datetime",
        size: 17,
        cell: (item) =>
          formatUTCDateForLocale(
            item.row.original.utcSubmittedAt,
            item.row.original.accountBillingCountryCode,
          ),
      },
      {
        header: "Submitted by",
        accessorKey: "submittedByName",
        size: 22,
      },
      {
        header: "Information",
        accessorKey: "information",
        size: 25,
        cell: (item) => {
          const migrationSet = item.row.original;
          switch (migrationSet.status) {
            case "In-progress":
            case "Submitted":
              return "Pending completion";
            case "Loading":
              const percentage = calculateLoadingPercentage(migrationSet);
              return `Pending completion - ${percentage}%`;
            case "Failed Validation":
            case "Complete":
              return <MigrationSetError migrationSet={migrationSet} />;
            default:
              return "Unknown status";
          }
        },
      },
      {
        header: "Status",
        accessorKey: "status",
        size: 15,
        cell: (item) => {
          const statusValue = item.cell.getValue(); // Directly get the status value from the item
          let pillColor: Scheme;
          switch (statusValue) {
            case "In-progress":
            case "Loading":
              pillColor = "WARNING";
              break;
            case "Failed Validation":
              pillColor = "ERROR";
              break;
            case "Submitted":
              pillColor = "READY";
              break;
            case "Complete":
              pillColor = "SUCCESS";
              break;
            default:
              pillColor = "ERROR";
          }
          return (
            <Pill
              lineHeight={1.5}
              borderRadius={16}
              fontSize={14}
              padding={"0.4rem 1.2rem"}
              text={`${statusValue}`}
              colorScheme={pillColor}
            />
          );
        },
      },
      {
        header: "",
        accessorKey: "openView",
        size: 17,
        cell: (item) => {
          const openViewText =
            item.row.original.status === "Submitted" ||
            item.row.original.status === "Loading" ||
            item.row.original.status === "Complete"
              ? "View Summary"
              : "Open Import";
          return (
            <OpenViewButton
              onClick={() =>
                selectMigration(
                  item.row.original.migrationSetId,
                  item.row.original.status,
                )
              }
              status={`${openViewText}`}
            >
              {openViewText}
            </OpenViewButton>
          );
        },
      },
    ],
    [selectMigration],
  );

  const sortedData = useMemo(() => {
    return data.sort((a, b) => {
      // Item with null utcSubmittedAt should appear first
      if (a.utcSubmittedAt === null) return -1;
      if (b.utcSubmittedAt === null) return 1;

      // Sort by utcSubmittedAt in descending order
      const dateA = new Date(a.utcSubmittedAt!).getTime();
      const dateB = new Date(b.utcSubmittedAt!).getTime();
      return dateB - dateA;
    });
  }, [data]);

  return (
    <>
      <PageBox isExistingMigration={true} padding="3.4rem 2.4rem">
        <Table data={sortedData} columns={cols} />
      </PageBox>
    </>
  );
};

export { ExistingMigration };
