import React, { useState } from "react";
import { useTheme } from "@emotion/react";
import ReactModal from "react-modal";
import styled from "@emotion/styled";
import { X, Zap } from "lucide-react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleCheck } from "@fortawesome/free-solid-svg-icons";
import {
  Checkbox,
  Loader,
  StandardButton,
  SubmitButton,
} from "../../../components";
import { COLOURS, ImportTypes } from "../../../shared";
import { useAuthContext } from "../authContext";
import { getRegionFromURL } from "../../../shared/utils/region";
import { PRODUCT_TIERS } from "../../../shared/utils/constants";
import log from "../../../shared/utils/log";

const StyledModal = styled.div`
  width: 56rem;
  max-height: 75.7rem;
  border-radius: 0.8rem;
  background-color: ${COLOURS.importModalContainerBackground};
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;

  // Media query for screens below 950px in height
  @media (max-height: 950px) {
    max-height: 65rem;
  }

  // Media query for screens below 850px in height
  @media (max-height: 850px) {
    max-height: 55rem;
  }

  // Media query for screens below 750px in height
  @media (max-height: 750px) {
    max-height: 50rem;
  }
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  height: 7.5rem;
  margin-left: 2.4rem;
  margin-right: 2.4rem;
`;

const IconWrapper = styled.div`
  background-color: ${COLOURS.newMigrationIconWrapperBackground};
  padding: 1.6rem;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const TitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 1.6rem;
  margin-top: 1.6rem;
`;

// Title styling
const Title = styled.h2`
  color: ${COLOURS.importModalHeadingColor};
  font-size: ${(props) => props.theme.fontSize.subHeading};
  font-weight: 600;
  margin: 0;
`;

const Subtitle = styled.p`
  color: ${COLOURS.importModalHeadingColor};
  font-size: ${(props) => props.theme.fontSize.base};
  font-weight: 500;
  margin-top: 0.4rem;
`;

const CloseButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 1.8rem;
  height: 1.8rem;
  padding: 0;
  background: none;
  border: none;
  cursor: pointer;
  margin-left: auto;
`;

// Divider & Other Containers
const Divider = styled.hr`
  border-top: 1px solid ${COLOURS.importModalDivider};
  margin: 0;
`;

const OptionsContainer = styled.div`
  max-height: 58.8rem;
  width: 56rem;
  overflow-y: auto;
  overflow-x: hidden;
  padding: 2.4rem 2.4rem 0.8rem 2.4rem;
`;

const OptionRow = styled.div<{ selected: boolean }>`
  margin-bottom: 0.95rem;
  border: 1px solid ${COLOURS.importModalImportTypeBorder};
  border-radius: 0.8rem;
  align-items: center;
  justify-content: center;
  padding: 0.7rem;  
  user-select: none;
  background-color: ${({ selected }) =>
    selected
      ? COLOURS.importModalImportTypeSelectedBackground
      : COLOURS.importModalContainerBackground};
`;

const Description = styled.p<{
  descriptionIsEmpty?: boolean;
  dependencyIsEmpty?: boolean;
}>`
  color: ${COLOURS.importModalImportTypeParagraphColor};
  font-size: ${(props) => props.theme.fontSize.small};
  font-weight: 400;
  line-height: 1.8rem;
  margin: ${(props) =>
    props.descriptionIsEmpty || props.dependencyIsEmpty
      ? "0 0 0 3.6rem"
      : "0 0 0 3.6rem"}; /* Only apply left margin */
  padding: ${(props) =>
    props.descriptionIsEmpty
      ? "0"
      : "0 0 0.2rem 0"}; /* Minimal bottom padding if description exists */
`;

const DependencyTextWrapper = styled.div<{ descriptionIsEmpty?: boolean }>`
  display: flex;
  align-items: center;
  margin-left: 3.6rem;
  margin-top: ${(props) =>
    props.descriptionIsEmpty
      ? "0"
      : "0.2rem"}; /* Add space if description is empty */
  margin-bottom: 0.5rem; /* Ensure minimal space between dependency text and border */
`;

const StyledCheckIcon = styled(FontAwesomeIcon)`
  color: ${COLOURS.importModalDependencyCheckIconBackground};
  width: 1.1rem;
  height: 1.1rem;
  margin-right: 0.5rem;
`;

const DependencyText = styled.p`
  color: ${COLOURS.importModalImportTypeParagraphColor};
  font-size: ${(props) => props.theme.fontSize.small};
  font-weight: 400;
  margin: 0;
`;

const Footer = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 2.4rem;
  height: 9.2rem;
`;

export interface ImportOption {
  label: string;
  description?: string;
  dependentOn?: string;
  importTypes: string[];
  selected: boolean;
}

export const ImportTypeSelectionModal: React.FC<{
  closeModal: () => void;
  onConfirm: (selectedOptions: string[]) => void;
}> = ({ closeModal, onConfirm }) => {
  const { productId } = useAuthContext();
  const region = getRegionFromURL();
  const theme = useTheme();
  const [importTypesToShow, setImportTypesToShow] = useState<string[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  // Matter relationships always needs to be associated with matters but should only be shown for Grow or Prosper clients
  const isGrowOrProsper = productId === PRODUCT_TIERS.GROW || productId === PRODUCT_TIERS.PROSPER;

  const [options, setOptions] = useState<ImportOption[]>(() => {
    const getTimeFeesDisbursementsRegionSpecificLabel = (region: string) => {
      switch (region) {
        case "AU":
          return "Unbilled Time, Fees, and Disbursements";
        case "US":
          return "Unbilled Time, Fees, and Expenses";
        case "GB":
          return "Unbilled Time, Fees, and Cost Recoveries";
        default:
          return "Unbilled Time, Fees, and Expenses"; // Default to US wording
      }
    };

    const getOpeningBalancesSpecificDescription = (region: string) => {
      switch (region) {
        case "AU":          
          return "Import Credit and Trust balances";
        case "US":
          return "Import Operating, Credit, and Trust balances";
        case "GB":
          return "Import Credit and Client account balances";
        default:
          return "Import Operating, Credit, and Trust balances";
      }
    };

    // Initialise options with region and tier logic
    let initialOptions: ImportOption[] = [
      {
        label: ImportTypes.Staff,
        selected: false,
        importTypes: [ImportTypes.Staff],
      },
      {
        label: ImportTypes.Contacts,
        description:
          "Can be imported on their own. However, if you also have Matters to import, they should be done alongside Contacts.",
        selected: false,
        importTypes: [ImportTypes.Contacts],
      },
      {
        label: ImportTypes.Matters,
        dependentOn: ImportTypes.Contacts,
        selected: false,
        importTypes: [
          ImportTypes.Contacts,
          ImportTypes.Matters,
          ImportTypes.MatterTypes,
          // ...(isGrowOrProsper ? [ImportTypes.MatterRelationships] : []),
        ],
      },
      {
        label: ImportTypes.OpeningBalances,
        description: getOpeningBalancesSpecificDescription(region),
        dependentOn: ImportTypes.Matters,
        selected: false,
        importTypes: [
          ImportTypes.Contacts,
          ImportTypes.Matters,
          ImportTypes.MatterTypes,
          // ...(isGrowOrProsper ? [ImportTypes.MatterRelationships] : []),
          ImportTypes.OpeningBalances,
        ],
      },
      {
        label: getTimeFeesDisbursementsRegionSpecificLabel(region),
        dependentOn: ImportTypes.Matters,
        selected: false,
        importTypes: [
          ImportTypes.Contacts,
          ImportTypes.Matters,
          ImportTypes.MatterTypes,
          // ...(isGrowOrProsper ? [ImportTypes.MatterRelationships] : []),
          ImportTypes.ActivityTypes,
          ImportTypes.UnbilledTimeAndFees,
          ImportTypes.UnbilledDisbursements,
        ],
      },
      {
        label: ImportTypes.Invoices,
        dependentOn: ImportTypes.Matters,
        selected: false,
        importTypes: [
          ImportTypes.Contacts,
          ImportTypes.Matters,
          ImportTypes.MatterTypes,          
          // ...(isGrowOrProsper ? [ImportTypes.MatterRelationships] : []),
          ImportTypes.Invoices,
        ],
      },
    ];

    // Use the enum similarly in the conditions where Boost, Grow, and Prosper product tiers apply
    if (
      productId === PRODUCT_TIERS.BOOST ||
      productId === PRODUCT_TIERS.GROW ||
      productId === PRODUCT_TIERS.PROSPER
    ) {
      initialOptions.push(
        {
          label: ImportTypes.Memos,
          dependentOn: ImportTypes.Matters,
          selected: false,
          importTypes: [
            ImportTypes.Contacts,
            ImportTypes.Matters,
            ImportTypes.MatterTypes,
            // ...(isGrowOrProsper ? [ImportTypes.MatterRelationships] : []),
            ImportTypes.Memos,
          ],
        },
        {
          label: ImportTypes.Tasks,
          description:
            "Can optionally be associated with a Matter. Therefore, Matters will also be made available for import.",
          selected: false,
          importTypes: [
            ImportTypes.Contacts,
            ImportTypes.Matters,
            ImportTypes.MatterTypes,
            // ...(isGrowOrProsper ? [ImportTypes.MatterRelationships] : []),
            ImportTypes.Tasks,
          ],
        },
      );
    }

    // Add Safe Custody Packets and Documents only for Grow/Prosper tiers and non-US regions
    if (
      (productId === PRODUCT_TIERS.GROW ||
        productId === PRODUCT_TIERS.PROSPER) &&
      region !== "US"
    ) {
      initialOptions.push({
        label: "Safe Custody Packets and Documents",
        description:
          "Can optionally be associated with a Matter. Therefore, Matters will also be made available for import.",
        dependentOn: ImportTypes.Contacts,
        selected: false,
        importTypes: [
          ImportTypes.Contacts,
          ImportTypes.Matters,
          ImportTypes.MatterTypes,
          // ImportTypes.MatterRelationships,
          ImportTypes.SafeCustodyPackets,
          ImportTypes.SafeCustodyDocuments,
        ],
      });
    }
    return initialOptions;
  });

  const handleOptionChange = (index: number) => {
    const newOptions = [...options];
    newOptions[index].selected = !newOptions[index].selected;

    const selectedOption = newOptions[index];

    // If the option is selected, add its cards to the list
    if (selectedOption.selected) {
      setImportTypesToShow((prevImportTypes) => {
        const newImportTypes = [...prevImportTypes];
        selectedOption.importTypes.forEach((importType) => {
          if (!newImportTypes.includes(importType)) {
            newImportTypes.push(importType); // Add the card if it's not already in the list
          }
        });
        return newImportTypes;
      });
    } else {
      // If the option is deselected, check if any other selected options require this option's import types
      setImportTypesToShow((prevImportTypes) => {
        const newImportTypes = prevImportTypes.filter(
          (importType) => !selectedOption.importTypes.includes(importType) || 
          // Keep import types that are still required by other selected options
          newOptions.some(option => 
            option.selected && option.importTypes.includes(importType)
          )
        );
        return newImportTypes;
      });
    }
    setOptions(newOptions);
  };

  const handleUnselectAll = () => {
    setOptions(options.map((option) => ({ ...option, selected: false })));
    setImportTypesToShow([]); // Clear import types when unselecting all
  };

  const handleSelectAll = () => {
    // Always show these import types for all tiers
    const defaultImportTypes = [
      ImportTypes.Staff,
      ImportTypes.Contacts,
      ImportTypes.Matters,
      ImportTypes.MatterTypes,
      // ...(isGrowOrProsper ? [ImportTypes.MatterRelationships] : []),
      ImportTypes.OpeningBalances,
      ImportTypes.ActivityTypes,
      ImportTypes.UnbilledTimeAndFees,
      ImportTypes.UnbilledDisbursements,
      ImportTypes.Invoices,
    ];

    importTypesToShow.push(...defaultImportTypes);

    // Add region and tier specific imports
    if (
      productId === PRODUCT_TIERS.BOOST ||
      productId === PRODUCT_TIERS.GROW ||
      productId === PRODUCT_TIERS.PROSPER
    ) {
      importTypesToShow.push(ImportTypes.Memos, ImportTypes.Tasks);
    }

    if (
      (productId === PRODUCT_TIERS.GROW ||
        productId === PRODUCT_TIERS.PROSPER) &&
      region !== "US"
    ) {
      importTypesToShow.push(ImportTypes.SafeCustodyPackets, ImportTypes.SafeCustodyDocuments);
    }

    setOptions(options.map((option) => ({ ...option, selected: true })));
    setImportTypesToShow(importTypesToShow);
  };

  const handleProceed = async () => {
    setIsLoading(true);
    try {
      // Deduplicate the import types to ensure no duplicates
      const uniqueImportTypes = importTypesToShow.filter(
        (type, index, self) => {
          return self.indexOf(type) === index;
        },
      );
      log("Final unique import types: ", uniqueImportTypes);

      onConfirm(uniqueImportTypes); // Pass deduplicated string array to parent
      closeModal(); // Close the modal after proceeding
    } catch (error) {
      console.error("Error during import confirmation:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const allSelected = options.every((option) => option.selected); // Check if all options are selected
  const selectedCount = options.filter((option) => option.selected).length;

  return (
    <ReactModal
      isOpen={true}
      onRequestClose={closeModal}
      style={{
        overlay: {
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          backgroundColor: "rgba(0, 0, 0, 0.25)",
          padding: 0,
        },
        content: {
          position: "relative",
          borderRadius: "1.2rem",
          margin: "0rem auto",
          padding: 0,
        },
      }}
    >
      <StyledModal>
        {/* Modal Header */}
        <Header>
          <IconWrapper>
            <Zap
              color={COLOURS.newMigrationIconColor}
              strokeWidth={2}
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            />
          </IconWrapper>
          <TitleContainer>
            <Title>New Import</Title>
            <Subtitle>Select items to include in your import</Subtitle>
          </TitleContainer>
          <CloseButtonWrapper onClick={closeModal}>
            <X
              size={18}
              strokeWidth={2}
              color={COLOURS.importModalCloseButton}
            />
          </CloseButtonWrapper>
        </Header>

        {/* Dividing Line Below Heading */}
        <Divider />

        {/* Options List */}
        <OptionsContainer>
          {options.map((option, index) => (
            <OptionRow
              key={index}
              selected={option.selected}
              onClick={() => handleOptionChange(index)}
            >
              <Checkbox
                id={`option-${index}`}
                name="importOption"
                value={option.label}
                checked={option.selected}
                onChange={() => handleOptionChange(index)}
                label={option.label}
                index={0}
                type="checkbox"
                labelFontSize={theme.fontSize.base}
                labelColor={COLOURS.importModalImportTypeHeadingColor}
                descriptionFontSize={theme.fontSize.small}
                descriptionColor={COLOURS.importModalImportTypeParagraphColor}
                shouldStopPropagation={true}
              />
              {option.description && (
                <Description
                  descriptionIsEmpty={!option.description}
                  dependencyIsEmpty={!option.dependentOn}
                >
                  {option.description}
                </Description>
              )}
              {option.dependentOn && (
                <DependencyTextWrapper descriptionIsEmpty={!option.description}>
                  <StyledCheckIcon icon={faCircleCheck} />
                  <DependencyText>
                    {option.dependentOn} also required for import
                  </DependencyText>
                </DependencyTextWrapper>
              )}
            </OptionRow>
          ))}
        </OptionsContainer>

        {/* Dividing Line Between Options and Buttons */}
        <Divider />

        {/* Modal Footer with Buttons */}
        <Footer>
          <StandardButton
            onClick={allSelected ? handleUnselectAll : handleSelectAll} // Toggle between select/unselect all
            fontSize={theme.fontSize.emphasis}
            width="calc(50% - 0.6rem)"
            height="4.4rem"
            padding="1.2rem 2.0rem"
          >
            {allSelected ? "Unselect All" : "Select All"}
          </StandardButton>
          <SubmitButton
            onClick={handleProceed}
            disabled={selectedCount === 0}
            isSubmitButtonDisabled={selectedCount === 0}
            disableButtonAccess={false}
            fontSize={theme.fontSize.emphasis}
            width="calc(50% - 0.6rem)"
            height="4.4rem"
            padding="1.2rem 2.0rem"
          >
            {`${selectedCount} Selected, Proceed`}
          </SubmitButton>
        </Footer>
      </StyledModal>
      {isLoading && <Loader />}
    </ReactModal>
  );
};
