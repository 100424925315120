import React from "react";
import { Timer } from "lucide-react";
import { QueryObserverResult } from "react-query";
import {
  COLOURS,
  ImportTypes,
  MigrationSetImport,
  MigrationSetWithImports,
} from "../../shared";
import { Card } from "../card";
import {
  getUnbilledTimeAndFeesDescription,
  getTemplateKey,
  getUnbilledTimeAndFeesTooltip,
} from "../../functions/workflowFunctions";
import { CardDescription } from "../cardDescription";

interface CardUnbilledTimeAndFeeProps {
  currentMigrationSetId: number;
  data: { imports: MigrationSetImport[] } | undefined;
  cardsState: Record<string, any>;
  disableButtonAccess: boolean;
  refetchMigrationSet: () => Promise<
    QueryObserverResult<MigrationSetWithImports, unknown>
  >;
  region: string;
}

export const CardUnbilledTimeAndFee: React.FC<CardUnbilledTimeAndFeeProps> = ({
  currentMigrationSetId,
  data,
  cardsState,
  disableButtonAccess,
  refetchMigrationSet,
  region,
}) => {
  return (
    <Card
      image={
        <Timer
          size={60}
          color={COLOURS.cardIconColor}
          strokeWidth={2}
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            padding: "0.625rem",
          }}
        />
      }
      importType="unbilledTimeAndFee"
      templateKey={getTemplateKey("unbilledTimeAndFee", region)}
      fileName="unbilledTimeAndFee.csv"
      migrationSetId={currentMigrationSetId}
      migrationSetImport={data?.imports.find(
        (i) => i.importType === "unbilledTimeAndFee",
      )}
      headerText={ImportTypes.UnbilledTimeAndFees}
      description={
        <CardDescription
          {...getUnbilledTimeAndFeesDescription(
            region,
            data?.imports.find((i) => i.importType === "unbilledTimeAndFee"),
          )}
        />
      }
      cardState={cardsState["unbilledTimeAndFee"]}
      disableButtonAccess={disableButtonAccess}
      refetchMigrationSet={refetchMigrationSet}
      tooltipMessage={getUnbilledTimeAndFeesTooltip(region)}
    />
  );
};
