import React from "react";
import styled from "@emotion/styled";
import { COLOURS } from "../shared";
import { Info } from "lucide-react";

const NotesContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  margin: 1rem;
  width: 25%;
  background-color: ${COLOURS.notesContainerBackground};
  border: 1px solid ${COLOURS.notesContainerBorder};
  border-radius: 0.8rem;
  padding: 0.8rem;
  @media (max-width: 1279px) {
    width: 33%;
  }
`;

const NotesHeading = styled.div`
  font-size: ${(props) => props.theme.fontSize.emphasis};
  font-weight: 700;
  margin-top: 1.5rem;
  margin-bottom: 1rem;
`;

const NotesRow = styled.div`
  display: flex;
  align-items: flex-start;
  margin: 1rem 2rem 1rem 2rem;
`;

const IconWrapper = styled.div`
  margin-right: 1rem;
  align-self: flex-start;
  vertical-align: top;
`;

const TextWrapper = styled.div`
  line-height: 1.8rem;
  margin-bottom: 1.5rem;
  font-size: ${(props) => props.theme.fontSize.base};
  font-weight: 400;
  color: ${COLOURS.notesTextWrapperColor};
  vertical-align: top;
`;

const Link = styled.a`
  color: ${COLOURS.notesLinkColor};
  text-decoration: underline;
  cursor: pointer;
`;

export const Notes: React.FC = () => {
  const NoteItem = ({ text, link }: { text: string; link?: string }) => (
    <NotesRow>
      <IconWrapper>
        <Info size="20px" style={{ color: COLOURS.notesIconWrapperInfoColor, verticalAlign: "top" }} />
      </IconWrapper>
      <TextWrapper>
        {link ? (
          <>
            <Link href={link} target="_blank">
              {"Click here"}
            </Link>
            {text}
          </>
        ) : (
          text
        )}
      </TextWrapper>
    </NotesRow>
  );

  return (
    <NotesContainer>
      <NotesRow>
        <NotesHeading>Notes:</NotesHeading>
      </NotesRow>

      <NoteItem text="Uploaded files must be in CSV, XLSX, or XLS format." />
      <NoteItem text="Duplicate checks are NOT performed against existing data created pre-import." />
      <NoteItem
        text=" for more information on preparing your source data."
        link={process.env.REACT_APP_MIG_JUMPSTART_DOCUMENT_PREPARATION_URL}
      />
    </NotesContainer>
  );
};
