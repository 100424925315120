import {
  common,
  staff as StaffNS,
  host as HostNS,
} from "@smokeballdev/smokeball-client-sdk";

// Smokeball SDK
export type Client = common.Api;
export type Context = common.Context;
export type Staff = StaffNS.Staff;
export type Change = "matters" | "layouts" | "contacts" | "staff";
type Observe = Change[];
export type UseSmokeballAppOptions = {
  observe: Observe;
};
export type UseSDKApiOptions = {
  client: Client | null;
  hostType: "windows" | "web" | "local" | undefined;
  isInit: boolean;
  observe?: Observe;
};
export type UseLocalApiOptions = {
  context: Context;
  observe?: Observe;
};
export type LocalSmokeballAppContext = {
  firmId?: string;
  userId?: string;
  staff: Staff[];
};
export type Version = HostNS.Version;
// End Smokeball SDK

export type AccountBillingCountryCode = "US" | "AU" | "GB";
export type Guid = string;
export type SmokeballUserId = Guid;
export type ImportType =
  | "staff"
  | "contact"
  | "matter"
  | "matterType"
  | "matterRelationship"
  | "matterOpeningBalance"
  | "activity"
  | "unbilledExpense"
  | "unbilledTimeAndFee"
  | "invoice"
  | "invoiceItem"
  | "memo"
  | "task"
  | "safeCustodyPacket"
  | "safeCustodyDocument";
export type TemplateKey =
  // ALL REGIONS
  | "staffImport"
  | "activityImport"
  | "matterRelationshipImport"
  | "memoImport"
  | "taskImport"
  | "safeCustodyPacketImport" // Only visible in AU and GB
  | "safeCustodyDocumentImport" // Only visible in AU and GB
  // US
  | "contactImportUS"
  | "matterImportUS"
  | "matterTypeImportUS"
  | "matterOpeningBalanceImportUS"
  | "unbilledExpenseImportUS"
  | "unbilledTimeAndFeeImportUS"
  | "invoiceImportUS"
  | "invoiceItemImportUS"
  // AU
  | "contactImportAU"
  | "matterImportAU"
  | "matterTypeImportAU"
  | "matterOpeningBalanceImportAU"
  | "unbilledExpenseImportAU"
  | "unbilledTimeAndFeeImportAU"
  | "invoiceImportAU"
  | "invoiceItemImportAU"
  // GB
  | "contactImportGB"
  | "matterImportGB"
  | "matterTypeImportGB"
  | "matterOpeningBalanceImportGB"
  | "unbilledExpenseImportGB"
  | "unbilledTimeAndFeeImportGB"
  | "invoiceImportGB"
  | "invoiceItemImportGB";
export type MigrationSetStatus =
  | "In-progress"
  | "Submitted"
  | "Loading"
  | "Failed Validation"
  | "Complete"
  | "Cancelled";
export type MigrationSetLoadStatus =
  | "Awaiting-Validation"
  | "Validation-In-Progress"
  | "Validation-Error"
  | "Transform-In-Progress"
  | "Transform-Error"
  | "Awaiting-Load-Approval"
  | "Awaiting-Load"
  | "Load-In-Progress"
  | "Load-Complete";

export interface MigrationSet {
  migrationSetId: number;
  accountId: Guid;
  migrationSetNbr: number;
  accountName: string;
  accountBillingCountry: string;
  accountBillingCountryCode: AccountBillingCountryCode;
  accountBillingStatus: string;
  accountBillingState: string;
  accountSalesforceId: string;
  status: MigrationSetStatus;
  loadStatus: MigrationSetLoadStatus;
  validationLogFileName: string;
  loadLogFileName: string;
  migrationDataDB: string;
  importTypes: string;
  sourceSystem: string;
  partner: string;
  hasMattersInMultipleStates: boolean;
  utcSubmittedAt: Date | null;
  submittedBy: SmokeballUserId | null;
  submittedByName: string;
  utcLoadStartedAt: Date | null;
  utcLoadCompletedAt: Date | null;
  utcCompletedAt: Date | null;
  utcCreatedAt: Date | null;
  createdBy: SmokeballUserId;
  createdByName: string;
  utcCancelledAt: Date | null;
  cancelledBy: SmokeballUserId | null;
  cancelledByName: string;
  utcLastUpdatedAt: Date;
  lastUpdatedBy: SmokeballUserId | null;
  lastUpdatedByName: string;
}

export interface MigrationSetImport {
  migrationImportId: number;
  migrationSetId: number;
  accountId: Guid;
  importType: ImportType;
  fileName: string;
  uploadInProgress: Boolean;
  loadInProgress: Boolean;
  hasUploaded: Boolean;
  embedId: string | null;
  sessionToken: string | null;
  templateKey: TemplateKey;
  uploadTotalRecordCount: number;
  uploadErrorCount: number;
  uploadWarningCount: number;
  loadTotalRecordCount: number;
  totalRecordCountForLoad: number;
  loadErrorCount: number;
  utcLastUpdatedAt: Date;
  lastUpdatedBy: SmokeballUserId | null;
  lastUpdatedByName: string;
  balances?: MatterOpeningBalances | UnbilledTimeFeeBalances | UnbilledExpenseBalances | InvoiceBalances;
}

export interface MigrationSetImportWithOneSchemaSession
  extends MigrationSetImport {
  embedId: string;
  sessionToken: string;
}

export interface MigrationSetImportWithUploadLink extends MigrationSetImport {
  uploadDetails: MigrationUploadLink;
}

export interface MigrationSetWithImports extends MigrationSet {
  imports: MigrationSetImport[];
}

export interface MigrationUploadLink {
  presignedURL: string;
  userJwt: string;
}

export interface MatterOpeningBalances {
  operating?: number;
  credit: number;
  trust: number;
}

export interface UnbilledTimeFeeBalances {
  totalex: number;
  totalinc: number;
  billableex: number;
  billableinc: number;
  nonbillableex: number;
  nonbillableinc: number;
  waivedex: number;
  waivedinc: number;
}

export interface UnbilledExpenseBalances {
  totalex: number;
  totalinc: number;
  billableex: number;
  billableinc: number;
  nonbillableex: number;
  nonbillableinc: number;
  waivedex: number;
  waivedinc: number;
}

export interface InvoiceBalances {
  totalinc: number;
  dueinc: number;
  feeex: number;
  feeinc: number;
  expenseex: number;
  expenseinc: number;
}

// importTypes.ts
export enum ImportTypes {
  Staff = "Staff",
  Contacts = "Contacts",
  Matters = "Matters",
  MatterTypes = "Matter Types",
  MatterRelationships = "Matter Relationships",
  OpeningBalances = "Opening Balances",
  ActivityTypes = "Activity Types",
  UnbilledTimeAndFees = "Unbilled Time and Fees",
  UnbilledDisbursements = "Unbilled Disbursements",
  Invoices = "Invoices",
  InvoiceItems = "Invoice Items",
  Memos = "Memos",
  Tasks = "Tasks",
  SafeCustodyPackets = "Safe Custody Packets",
  SafeCustodyDocuments = "Safe Custody Documents"
}
