import React, { useState } from "react";
import { Pill, Scheme } from "./pill";
import {
  COLOURS,
  ImportType,
  ImportTypes,
  MigrationSetImport,
  TemplateKey,
  useSmokeballApp,
} from "../shared";
import { StandardButton } from "./buttons";
import styled from "@emotion/styled";
import {
  ImporterInitialisationParams,
  useOpenOneSchemaSession,
} from "../hooks";
import { ClearMigrationDataTypeUploadModal } from "../routes/basic/workflow/clearMigrationDataTypeUploadModal";
import { useCompletedDataType } from "../hooks/completeMigrationDataTypeUpload";
import { Loader } from "./loader";
import { MatterStateSelectionModal } from "../routes/basic/workflow/matterStateSelectionModal";
import { CardState } from "../routes/basic/workflow";
import { Tooltip } from "./toolTip";
import { Check, CircleMinus, Clock } from "lucide-react";
import { formatWithPluralise, getSingularForm } from "../shared/utils";
import {
  pluralWords,
  getRegionSpecificExpenseHeader,
} from "../shared/utils/constants";

interface ExtendedButtonProps {
  cursor?: string;
  isDisabled?: boolean;
  isReady?: boolean;
  hasErrors?: boolean;
  hasWarnings?: boolean;
  isSuccess?: boolean;
  errorCount?: number;
  warningCount?: number;
  migrationSetId?: number;
}

const StandardButtonExtender = styled(StandardButton)<ExtendedButtonProps>`
  cursor?: ${(props) => (props.isDisabled ? "not-allowed" : "pointer")};
  isready?: boolean;
  haserrors?: boolean;
  haswarnings?: boolean;
  issuccess?: boolean;
  errorcount?: number;
  warningcount?: number;
  migrationsetid?: number;
  min-width: 6rem;
  align-items: center;
`;

const EditUploadButton = styled(StandardButtonExtender)`
  color: ${COLOURS.primaryButtonColor};
  border: 1px solid ${COLOURS.primaryButtonBorder};
  background: ${COLOURS.primaryButtonBackground};
  margin-left: 1rem;
`;

// TO BE REINSTATED IN LATER VERSION
// const ReplaceButton = styled(StandardButtonExtender)`
//   color: ${COLOURS.replaceButtonColor};
//   border: 1px solid ${COLOURS.replaceButtonBorder};
//   background: ${COLOURS.replaceButtonBackground};
// `;

const ButtonsRow = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

interface ButtonProps {
  buttonPosition: string;
}

const Buttons = styled.div<ButtonProps>`
  display: flex;
  align-items: center;
  gap: 0.4rem;
  justify-content: ${(props) =>
    props.buttonPosition === "left" ? "flex-start" : "flex-end"};
`;

const CardWrapper = styled.div`
  background-color: ${COLOURS.cardWrapperBackground};
  border: 1px solid ${COLOURS.cardWrapperBorder};
  border-radius: 0.8rem;
  margin: 0.8rem;
  padding: 2.4rem;
  width: calc(50% - 1.6rem - 1px);
  @media (max-width: 1511px) {
    padding: 1.5rem; /* Adjusted margins for smaller screens */
  }
  @media (max-width: 1421px) {
    padding: 2.4rem;
    min-width: calc(
      100% - 1.6rem - 1px
    ); /* Adjusted card width for smaller screens and added padding */
  }
`;

const CardContainer = styled.div<Pick<CardProps, "isDisabled">>`
  position: relative
  display: flex;
  flex-direction: column;
  height: 100%;
  min-height: 20rem;
  opacity: ${({ isDisabled }) => (isDisabled ? 0.5 : 1)};
  pointer-events: ${({ isDisabled }) => (isDisabled ? "none" : "auto")};
`;

interface IconWrapperProps {
  backgroundColor?: string;
}

export const CardIconWrapper = styled.div<IconWrapperProps>`
  width: 3.8rem;
  height: 3.8rem;
  border-radius: 20%;
  background-color: ${COLOURS.cardIconWrapperBackground};
  display: flex;
  justify-content: center;
  align-items: center;
`;

const CardRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0.25rem;
  max-height: 3.2rem;
  min-height: 3.2rem;

  h2 {
    width: auto;
    color: ${COLOURS.cardHeading2Color};
    font-size: ${(props) => props.theme.fontSize.subHeading};
    font-style: normal;
    font-weight: 700;
    margin-right: 1rem;
    margin-top: 3rem;
    flex-shrink: 0;
  }

  p {
    width: auto;
    color: ${COLOURS.cardParagraphColor};
    text-align: left;
    font-size: ${(props) => props.theme.fontSize.base};
    font-style: normal;
    font-weight: 400;
    margin-top: 3rem;
  }
`;
const CardParagraph = styled.div`
    color: ${COLOURS.cardParagraphColor};
    text-align: left;
    font-size: ${(props) => props.theme.fontSize.base};
    font-style: normal;
    font-weight: 400;
    line-height: 1.8rem;
    margin-left: 0.25rem;
    margin-bottom: 1rem;
    margin-top: 1.5rem;
    min-height: 8rem;
  }
`;

const PillContainer = styled.div`
  display: flex;
  gap: 0.8rem;
`;

const ExistingStaffText = styled.span`
  margin-right: 0.8rem;
`;

// Text for screens 2000px and up, and below 1280px
const LargeAndExtraSmallDisplayText = styled.span`
  @media (min-width: 2000px), (max-width: 1279px) {
    display: inline;
  }
  @media (min-width: 1280px) and (max-width: 1999px) {
    display: none;
  }
`;

// Text for screens less than 2000px down to 1680px, hidden below 1280px
const MediumDisplayText = styled.span`
  @media (min-width: 1680px) and (max-width: 1999px) {
    display: inline;
  }
  @media (min-width: 2000px), (max-width: 1679px) {
    display: none;
  }
`;

// Text for screens less than 1680px and above or equal to 1280px
const SmallDisplayText = styled.span`
  @media (min-width: 1280px) and (max-width: 1679px) {
    display: inline;
  }
  @media (min-width: 1680px), (max-width: 1279px) {
    display: none;
  }
`;

interface CardProps {
  region?: string;
  image: React.ReactNode;
  migrationSetImport: MigrationSetImport | undefined;
  importType: ImportType;
  templateKey: TemplateKey;
  fileName: string;
  isDisabled?: boolean;
  migrationSetId: number;
  headerText: string;
  description: string | React.ReactNode;
  cardState: CardState;
  disableButtonAccess: boolean;
  tooltipMessage?: string;
  refetchMigrationSet: () => Promise<any>;
}

export const Card: React.FC<CardProps> = ({
  region,
  migrationSetId,
  migrationSetImport,
  importType,
  templateKey,
  fileName,
  image,
  headerText,
  description,
  cardState,
  disableButtonAccess,
  tooltipMessage,
  refetchMigrationSet,
}) => {
  const { context, staff } = useSmokeballApp();

  const [isClearModalOpen, setIsClearModalOpen] = useState(false);
  const [isSelectMatterStatesModalOpen, setIsSelectMatterStatesModalOpen] =
    useState(false);
  const [isOneSchemaLoading, setIsOneSchemaLoading] = useState<boolean>(false);

  const pills = [];

  const existingStaffCount = staff?.current?.length || 0;

  const { handleCompletedData, isLoading } = useCompletedDataType();
  const openOneSchema = useOpenOneSchemaSession({
    refetchMigrationSet,
    setIsOneSchemaLoading,
  });

  const handleUploadConfigureRevalidateClick = async (
    hasMattersInMultipleStates = false,
  ) => {
    const uploadParameters: ImporterInitialisationParams = {
      importType,
      accountId: context?.firmId || "",
      templateKey,
      fileName,
      migrationSetId,
      hasMattersInMultipleStates,
      migrationSetImport,
      handleCompletedData,
    };

    openOneSchema(uploadParameters);
  };

  const openClearModal = () => {
    setIsClearModalOpen(true);
  };

  const closeClearModal = () => {
    setIsClearModalOpen(false);
  };

  const openSelectMatterStatesModal = () => {
    setIsSelectMatterStatesModalOpen(true);
  };

  const closeSelectMatterStatesModal = () => {
    setIsSelectMatterStatesModalOpen(false);
  };

  const hasErrors = cardState?.hasErrors;
  const hasWarnings = cardState?.hasWarnings;
  const needsRevalidation = cardState?.needsRevalidation;
  const isDisabled = cardState?.isDisabled;
  const isSuccess = cardState?.isSuccess;
  const isReady = cardState?.isReady;
  const hasUploaded = cardState?.hasUploaded;

  const uploadErrorCount = cardState?.uploadErrorCount ?? 0;
  const uploadWarningCount = cardState?.uploadWarningCount ?? 0;
  const uploadTotalRecordCount = cardState?.uploadTotalRecordCount ?? 0;

  if (
    isReady &&
    !isSuccess &&
    !hasErrors &&
    !hasWarnings &&
    !needsRevalidation
  ) {
    pills.push({
      type: "READY",
      text: "Ready",
      Icon: (
        <Clock
          size={14}
          color={COLOURS.pillIconColor}
          strokeWidth={2}
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            marginRight: "0.3rem",
          }}
        />
      ),
    });
  }
  if (hasWarnings && !isDisabled) {
    pills.push({
      type: "WARNING",
      text: (
        <>
          <LargeAndExtraSmallDisplayText>
            {`${formatWithPluralise({
              count: uploadWarningCount,
              headerText: "Warning",
            })} to review`}
          </LargeAndExtraSmallDisplayText>
          <MediumDisplayText>
            {`${formatWithPluralise({
              count: uploadWarningCount,
              headerText: "Warning",
            })}`}
          </MediumDisplayText>
          <SmallDisplayText>
            {`${formatWithPluralise({
              count: uploadWarningCount,
              headerText: "Warning",
            })}`}
          </SmallDisplayText>
        </>
      ),
    });
  }
  if (hasErrors && !isDisabled) {
    pills.push({
      type: "ERROR",
      text: (
        <>
          <LargeAndExtraSmallDisplayText>
            {`${formatWithPluralise({
              count: uploadErrorCount,
              headerText: "Error",
            })} to review`}
          </LargeAndExtraSmallDisplayText>
          <MediumDisplayText>
            {`${formatWithPluralise({
              count: uploadErrorCount,
              headerText: "Error",
            })}`}
          </MediumDisplayText>
          <SmallDisplayText>
            {`${formatWithPluralise({
              count: uploadErrorCount,
              headerText: "Error",
            })}`}
          </SmallDisplayText>
        </>
      ),
    });
  }
  if (needsRevalidation && !isLoading && !isOneSchemaLoading && !isDisabled) {
    pills.push({
      type: "ERROR",
      text: "Re-validate data",
      Icon: (
        <CircleMinus
          size={14}
          color={COLOURS.pillIconColor}
          strokeWidth={2}
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            marginRight: "0.3rem",
          }}
        />
      ),
      tooltip:
        "Re-validation is required as a dependent set of data has been modified.",
    });
  }
  if (isDisabled) {
    pills.push({
      type: "DISABLED",
      text: "Disabled",
      Icon: (
        <CircleMinus
          size={14}
          color={COLOURS.pillIconColor}
          strokeWidth={2}
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            marginRight: "0.3rem",
          }}
        />
      ),
    });
  }
  if (isSuccess) {
    pills.push({
      type: "SUCCESS",
      text: "Valid",
      Icon: (
        <Check
          size={14}
          color={COLOURS.pillIconColor}
          strokeWidth={2}
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            marginRight: "0.3rem",
          }}
        />
      ),
    });
  }

  return (
    <>
      <CardWrapper>
        <Tooltip enabled={isDisabled} message={tooltipMessage}>
          <CardContainer isDisabled={isDisabled}>
            <CardRow>
              <CardIconWrapper>{image}</CardIconWrapper>
              <PillContainer>
                {pills.map((pill, index) => (
                  <Tooltip
                    key={index}
                    enabled={!!pill.tooltip}
                    message={pill.tooltip}
                    position="top"
                  >
                    <Pill
                      lineHeight={1.4}
                      borderRadius={3}
                      fontSize={14}
                      icon={pill.Icon}
                      text={pill.text}
                      colorScheme={pill.type as Scheme}
                    />
                  </Tooltip>
                ))}
              </PillContainer>
            </CardRow>
            <CardRow>
              <h2>
                {headerText in pluralWords
                // Use the plural if it's in pluralWords or header text as is
                  ? pluralWords[headerText as keyof typeof pluralWords]
                  : headerText}
              </h2>
              <p>
                {(() => {
                  if (headerText === ImportTypes.Staff) {
                    return (
                      <>
                        {existingStaffCount > 0 && (
                          <ExistingStaffText>
                            <SmallDisplayText>
                              {`${existingStaffCount} existing`}
                            </SmallDisplayText>
                            <MediumDisplayText>
                              {`${formatWithPluralise({
                                count: existingStaffCount,
                                headerText,
                              })} existing`}
                            </MediumDisplayText>
                            <LargeAndExtraSmallDisplayText>
                              {`${formatWithPluralise({
                                count: existingStaffCount,
                                headerText,
                              })} existing`}
                            </LargeAndExtraSmallDisplayText>
                          </ExistingStaffText>
                        )}
                        {existingStaffCount > 0 && Boolean(hasUploaded) && " "}
                        {Boolean(hasUploaded) && uploadTotalRecordCount > 0 && (
                          <>
                            <SmallDisplayText>
                              {`${uploadTotalRecordCount} uploaded`}
                            </SmallDisplayText>
                            <MediumDisplayText>
                              {`${formatWithPluralise({
                                count: uploadTotalRecordCount,
                                headerText,
                              })} uploaded`}
                            </MediumDisplayText>
                            <LargeAndExtraSmallDisplayText>
                              {`${formatWithPluralise({
                                count: uploadTotalRecordCount,
                                headerText,
                              })} uploaded`}
                            </LargeAndExtraSmallDisplayText>
                          </>
                        )}
                      </>
                    );
                  } else if (headerText === ImportTypes.MatterRelationships) {
                    return (
                      Boolean(hasUploaded) &&
                      uploadTotalRecordCount > 0 &&
                      `${formatWithPluralise({
                        count: uploadTotalRecordCount,
                        headerText: "Relationship",
                      })} uploaded`
                    );
                  } else if (headerText === ImportTypes.OpeningBalances) {
                    return (
                      Boolean(hasUploaded) &&
                      uploadTotalRecordCount > 0 &&
                      `${formatWithPluralise({
                        count: uploadTotalRecordCount,
                        headerText: "Matter Balance",
                      })} uploaded`
                    );
                  } else if (
                    headerText === ImportTypes.UnbilledTimeAndFees ||
                    headerText ===
                      getRegionSpecificExpenseHeader(region || "")
                  ) {
                    return (
                      Boolean(hasUploaded) &&
                      uploadTotalRecordCount > 0 &&
                      `${formatWithPluralise({
                        count: uploadTotalRecordCount,
                        headerText: "Entry",
                      })} uploaded`
                    );
                  } else if (headerText === ImportTypes.SafeCustodyPackets) {
                    return (
                      Boolean(hasUploaded) &&
                      uploadTotalRecordCount > 0 &&
                      `${formatWithPluralise({
                        count: uploadTotalRecordCount,
                        headerText: "Packet",
                      })} uploaded`
                    );
                  } else if (headerText === ImportTypes.SafeCustodyDocuments) {
                    return (
                      Boolean(hasUploaded) &&
                      uploadTotalRecordCount > 0 &&
                      `${formatWithPluralise({
                        count: uploadTotalRecordCount,
                        headerText: "Document",
                      })} uploaded`
                    );
                  } else {
                    return (
                      Boolean(hasUploaded) &&
                      uploadTotalRecordCount > 0 &&
                      `${formatWithPluralise({
                        count: uploadTotalRecordCount,
                        headerText: getSingularForm(headerText),
                      })} uploaded`
                    );
                  }
                })()}
              </p>
            </CardRow>

            <CardParagraph>
              {typeof description === "string" ? description : description}
            </CardParagraph>
            <ButtonsRow>
              <Buttons buttonPosition="left">
                {migrationSetImport &&
                  migrationSetImport.importType !== "matterType" && (
                    <>
                      <StandardButtonExtender
                        onClick={openClearModal}
                        disabled={disableButtonAccess}
                      >
                        Clear
                      </StandardButtonExtender>
                    </>
                  )}
              </Buttons>
              <Buttons buttonPosition="right">
                {headerText === ImportTypes.MatterTypes ||
                (migrationSetImport &&
                  migrationSetImport.importType === "matterType") ? (
                  <EditUploadButton
                    onClick={() => handleUploadConfigureRevalidateClick(false)}
                    migrationSetId={migrationSetId}
                    disabled={disableButtonAccess}
                  >
                    Edit
                  </EditUploadButton>
                ) : null}

                {!migrationSetImport && headerText !== ImportTypes.MatterTypes  ? (
                  <EditUploadButton
                    onClick={
                      headerText === ImportTypes.Matters
                        ? () => openSelectMatterStatesModal()
                        : () => handleUploadConfigureRevalidateClick(false)
                    }
                    migrationSetId={migrationSetId}
                    disabled={disableButtonAccess}
                  >
                    Upload
                  </EditUploadButton>
                ) : null}

                {migrationSetImport &&
                  migrationSetImport.importType !== "matterType" && (
                    <>
                      {/* TO DO: TO BE ADDED At A LATER DATE IF NEEDED */}
                      {/* <ReplaceButton>Replace</ReplaceButton> */}

                      <EditUploadButton
                        onClick={() =>
                          handleUploadConfigureRevalidateClick(false)
                        }
                        migrationSetId={migrationSetId}
                        disabled={disableButtonAccess}
                      >
                        {needsRevalidation ? "Re-validate" : "Edit"}
                      </EditUploadButton>
                    </>
                  )}
              </Buttons>
            </ButtonsRow>
          </CardContainer>
        </Tooltip>

        {isClearModalOpen && (
          <ClearMigrationDataTypeUploadModal
            closeModal={closeClearModal}
            migrationSetImport={migrationSetImport}
            refetchMigrationSet={refetchMigrationSet}
          />
        )}
        {isSelectMatterStatesModalOpen && (
          <MatterStateSelectionModal
            closeModal={closeSelectMatterStatesModal}
            onConfirm={(hasMattersInMultipleStates) => {
              // Close the modal first
              closeSelectMatterStatesModal();

              // Then trigger upload with the selected multiple state value
              handleUploadConfigureRevalidateClick(hasMattersInMultipleStates);
            }}
            region={region || "US"}
          />
        )}
      </CardWrapper>
      {(isLoading || isOneSchemaLoading) && <Loader />}
    </>
  );
};
