import React from "react";
import styled from "@emotion/styled";

interface DescriptionProps {
  heading?: string;
  details?: { label: string; value: string }[];
  simpleText?: string;
  displayWiderColumn?: boolean;
}

const Heading = styled.div`
  font-size: ${(props) => props.theme.fontSize.base};
  margin-bottom: 0.5rem;
`;

const DetailGrid = styled.div<{
  hasLabel: boolean;
  displayWiderColumn: boolean;
}>`
  display: grid;
  grid-template-columns: ${(props) =>
    props.displayWiderColumn
      ? "225px auto" // Make it wider for invoices in UK and AU
      : props.hasLabel
        ? "max-content auto"
        : "auto"};
  gap: 0.5rem;
  font-size: ${(props) => props.theme.fontSize.small};
  max-width: 50%;
  @media (max-width: 5000px) {
    width: 20%;
  }
  @media (max-width: 3000px) {
    width: 28%;
  }
  @media (max-width: 1920px) {
    width: 35%;
  }
  @media (max-width: 1790px) {
    width: 33%;
  }
  @media (max-width: 1511px) {
    width: 35%;
  }
  @media (max-width: 1400px) {
    width: 32%;
  }
  @media (max-width: 1279px) {
    width: 30%;
  }
  @media (max-width: 1100px) {
    width: 32%;
  }
  @media (max-width: 1000px) {
    width: 42%;
  }
`;

const Label = styled.span`
  grid-column: 1;
  white-space: nowrap;
  align-self: center;
`;

const ValueContainer = styled.span<{ hasLabel: boolean }>`
  grid-column: ${(props) =>
    props.hasLabel ? "2" : "1"}; /* Span both columns if no label */
  display: flex;
  justify-content: ${(props) => (props.hasLabel ? "flex-end" : "flex-start")};
  align-items: center;
`;

const Value = styled.span<{ hasLabel: boolean }>`
  position: relative;
  text-align: ${(props) => (props.hasLabel ? "right" : "left")};
  font-variant-numeric: tabular-nums; /* Ensure equal-width digits */
  &::after {
    content: attr(data-decimal);
    position: absolute;
    right: 0;
    visibility: hidden;
  }
`;

const SimpleText = styled.p`
  font-size: ${(props) => props.theme.fontSize.base};
`;

const alignOnDecimal = (value: string) => {
  const parts = value.split(".");
  return (
    <>
      <span>{parts[0]}</span>
      {parts[1] && <span>.{parts[1]}</span>}
    </>
  );
};

const alignTwoAmounts = (value: string) => {
  const [firstAmount, secondAmount] = value.split("/");

  return (
    <>
      <span style={{ whiteSpace: "nowrap" }}>
        {alignOnDecimal(firstAmount)}/{secondAmount}
      </span>
    </>
  );
};

export const CardDescription: React.FC<DescriptionProps> = ({
  heading,
  details,
  simpleText,
  displayWiderColumn = false,
}) => {
  if (simpleText) {
    return <SimpleText>{simpleText}</SimpleText>;
  }

  return (
    <>
      {heading && <Heading>{heading}</Heading>}
      {details &&
        details.map((detail, index) => (
          <React.Fragment key={index}>
            {detail.label.trim() === "" && detail.value.trim() === "" ? (
              // To ensure blank lines display but does not impact USA values that have blank label
              <div style={{ height: "1rem" }} />
            ) : (
              <DetailGrid
                hasLabel={detail.label.trim() !== ""}
                displayWiderColumn={displayWiderColumn}
              >
                {detail.label.trim() !== "" && <Label>{detail.label}</Label>}
                <ValueContainer hasLabel={detail.label.trim() !== ""}>
                  <Value
                    hasLabel={detail.label.trim() !== ""}
                    data-decimal={detail.value}
                  >
                    {
                      detail.value.includes("/")
                        ? alignTwoAmounts(detail.value) // Handle two amounts
                        : alignOnDecimal(detail.value) // Handle a single amount
                    }
                  </Value>
                </ValueContainer>
              </DetailGrid>
            )}
          </React.Fragment>
        ))}
    </>
  );
};
