import styled from "@emotion/styled";

import { ButtonProps, buttonColor, buttonStyle } from "./utils";
import { COLOURS } from "../../shared";

export const Button = styled.button<ButtonProps>`
  ${buttonStyle}

  color: ${buttonColor};
  border: 1px solid ${buttonColor};
`;

export const StandardButton = styled.button<ButtonProps>`
  font-size: ${(props) => props.fontSize || props.theme.fontSize.base};
  font-style: normal;
  font-weight: 600; 
  display: flex;
  padding: ${(props) => props.padding || "1rem 1.8rem"};
  justify-content: center;
  align-items: baseline;
  border-radius: 0.5rem;
  border: 1px solid ${(props) => props.border || COLOURS.standardButtonBorder};
  background: ${(props) => props.background || COLOURS.standardButtonBackground};
  color: ${(props) => props.color || COLOURS.standardButtonColor};
  cursor: ${(props) => (props.disabled ? "default" : "pointer")};
  float: right;
  width: ${(props) => props.width || "auto"};
  height: ${(props) => props.height || "4rem"};
  text-transform: none;
  :after {
    content: none;
  }
  &:not(:first-child) {
    margin-left: 1rem;
  }
`;

export const SubmitButton = styled(StandardButton)<{
  isSubmitButtonDisabled: boolean;
  disableButtonAccess: boolean;
}>`
  border: 1px solid ${COLOURS.primaryButtonBorder};
  background: ${COLOURS.primaryButtonBackground};
  color: ${COLOURS.primaryButtonColor};
  opacity: ${(props) => (props.isSubmitButtonDisabled ? 0.5 : 1)};
  pointer-events: ${(props) => (props.disableButtonAccess ? "none" : "auto")};
`;

export const CancelMigrationButton = styled(StandardButton)<{
  disableButtonAccess: boolean;
}>`
  pointer-events: ${(props) => (props.disableButtonAccess ? "none" : "auto")};
`;

export const CancelButton = styled(StandardButton)`
  border: 1px solid ${COLOURS.cancelButtonBorder};
  background: ${COLOURS.cancelButtonBackground};
  color: ${COLOURS.cancelButtonColor};
`;

export const ButtonLink = styled.button`
  background: none;
  border: 0;

  margin: 0;
  font-size: inherit;
  text-decoration: none;
  cursor: pointer;
  color: ${COLOURS.buttonLinkColor};

  &:focus {
    outline: none;
  }
`;

export const Clickable = styled.div`
  background: none;
  border: 0;
  padding: 0;

  cursor: ${(props) => (props.onClick ? "pointer" : "initial")};
`;
