import log from "./log";
import { UnauthorizedError } from "./errors";

export async function requestHelper(
  accountId: string,
  endpoint: string,
  errorMessage: string,
  methodType: string,
  token?: () => Promise<string>,
) {
  const tokenValue = token && (await token());
  const fullUrl = `/import/status/${accountId}${endpoint}`;

  const response = await fetch(fullUrl, {
    headers: { authorization: `Bearer ${tokenValue}` },
    method: methodType,
  });

  if (response.status === 401) {
    log("Authorization error: 401 Unauthorized");
    throw new UnauthorizedError("Unauthorized access - 401 error.");
  }

  if (!response.ok) {
    log("Full URL with endpoint: ", fullUrl);
    log("Error message: ", errorMessage);

    throw new Error(`${errorMessage}`);
  }

  return response.json();
}
