import React from "react";
import { QueryObserverResult } from "react-query";
import {
  MigrationSetImport,
  MigrationSetWithImports,
} from "../../shared";
import { Card, CardIconWrapper } from "../card";
import { getRegionSpecificExpenseHeader } from "../../shared/utils/constants";
import {
  getUnbilledExpensesDescription,
  getTemplateKey,
  getUnbilledExpensesTooltip,
} from "../../functions/workflowFunctions";
import { CardDescription } from "../cardDescription";
import { UnbilledExpensesSVG, UnbilledExpensesUKSVG } from "../../icons";

interface CardUnbilledExpenseProps {
  currentMigrationSetId: number;
  data: { imports: MigrationSetImport[] } | undefined;
  cardsState: Record<string, any>;
  disableButtonAccess: boolean;
  refetchMigrationSet: () => Promise<
    QueryObserverResult<MigrationSetWithImports, unknown>
  >;
  region: string;
}

export const CardUnbilledExpense: React.FC<CardUnbilledExpenseProps> = ({
  currentMigrationSetId,
  data,
  cardsState,
  disableButtonAccess,
  refetchMigrationSet,
  region,
}) => {
  return (
    <Card
      image={
        region === "GB" ? (
          <CardIconWrapper>
            <UnbilledExpensesUKSVG />
          </CardIconWrapper>
        ) : (
          <CardIconWrapper>
            <UnbilledExpensesSVG />
          </CardIconWrapper>
        )
      }
      importType="unbilledExpense"
      templateKey={getTemplateKey("unbilledExpense", region)}
      fileName="unbilledExpense.csv"
      migrationSetId={currentMigrationSetId}
      migrationSetImport={data?.imports.find(
        (i) => i.importType === "unbilledExpense",
      )}
      headerText={getRegionSpecificExpenseHeader(region)}
      description={
        <CardDescription
          {...getUnbilledExpensesDescription(
            region,
            data?.imports.find((i) => i.importType === "unbilledExpense"),
          )}
        />
      }
      cardState={cardsState["unbilledExpense"]}
      disableButtonAccess={disableButtonAccess}
      refetchMigrationSet={refetchMigrationSet}
      tooltipMessage={getUnbilledExpensesTooltip(region)}
      region={region}
    />
  );
};
