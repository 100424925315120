import React from "react";
import { QueryObserverResult } from "react-query";
import {
  COLOURS,
  ImportTypes,
  MigrationSetImport,
  MigrationSetWithImports,
} from "../../shared";
import { Card } from "../card";
import { User } from "lucide-react";

interface CardMatterRelationshipProps {
  currentMigrationSetId: number;
  data: { imports: MigrationSetImport[] } | undefined;
  cardsState: Record<string, any>;
  disableButtonAccess: boolean;
  refetchMigrationSet: () => Promise<
    QueryObserverResult<MigrationSetWithImports, unknown>
  >;
  region: string;
}

export const CardMatterRelationship: React.FC<CardMatterRelationshipProps> = ({
  currentMigrationSetId,
  data,
  cardsState,
  disableButtonAccess,
  refetchMigrationSet,
  region,
}) => {
  const roleTerm = region === "US" ? "Attorney" : "Solicitor";
  const description = `Can be used to import additional Contacts to a Matter in roles other than Client, Other Side, and Other Side ${roleTerm}, which are defined in the Matters import file. Contacts and Matters must be uploaded and valid before Relationships can be added.`
  return (
    <Card
      image={
        <User
          color={COLOURS.cardIconColor}
          strokeWidth={2}
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        />
      }
      importType="matterRelationship"
      templateKey="matterRelationshipImport"
      fileName="matterRelationship.csv"
      migrationSetId={currentMigrationSetId}
      migrationSetImport={data?.imports.find(
        (i) => i.importType === "matterRelationship",
      )}
      headerText={ImportTypes.MatterRelationships}
      description={description}
      cardState={cardsState["matterRelationship"]}
      disableButtonAccess={disableButtonAccess}
      refetchMigrationSet={refetchMigrationSet}
      tooltipMessage="Contacts and Matters must be uploaded and valid before Relationships can be added."
      region={region}
    />
  );
};
