import styled from "@emotion/styled";
import { Clickable } from "./buttons";
import { COLOURS, useSmokeballApp } from "../shared";
import { XCircle, HelpCircle } from "lucide-react";
import React from "react";

const TitleRow = styled.div<{ direction: 'row' | 'column'; maxHeight?: number; minHeight?: number }>`
  display: flex;
  align-items: center;
  flex-direction: ${(props) => props.direction};
  justify-content: space-between;
  height: auto;
  min-height: ${(props) => props.minHeight}rem;
  max-height: ${(props) => props.maxHeight}rem;
  padding: 1rem;
  width: 100%;
`;

const IconWrapper = styled.a<{ iconType: string }>`
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 1rem;
  right: ${(props) =>
    props.iconType === "close"
     ? "1.3rem"
      : "4.65rem"}; // Adjust position based on icon type
  z-index: 100;

  svg {
    // Targeting SVG children directly
    width: 2.4rem;
    height: 2.4rem;
    padding: 0rem;
    border-radius: 1.2rem;
  }
`;

const Borderline = styled.div`
  border-top: 1px solid ${COLOURS.titleBorder};
  width: 100%;
`;

const TitleWrapper = styled.div<{opacity: number }>`
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: space-between;  
  background: ${COLOURS.titleWrapperBackground};
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.15);
  opacity: ${(props) => props.opacity};
  z-index: 1;

 .image,
 .button {
    flex: 0 0 auto;
  }

 .text {
    flex: 1;
    overflow: hidden;

    h1 {
      font-size: ${(props) => props.theme.fontSize.heading};
      font-weight: bold;
      margin: 0.3rem 0.8rem;

      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }
`;

export type TitleProps = {
  topLeftContent?: JSX.Element;
  bottomLeftContent?: JSX.Element;
  topRightContent?: JSX.Element;
  bottomRightContent?: JSX.Element;
  size?: number;
  opacity?: number;
  label?: string;
  link?: JSX.Element;
  onClick?: () => void;
};

const Title = ({
  topLeftContent,
  bottomLeftContent,
  topRightContent,
  bottomRightContent,
  opacity,
  label,
  link,
  onClick,
}: TitleProps) => {
  const { host } = useSmokeballApp();

  return (
    <TitleWrapper opacity={opacity || 1} aria-label={label}>
    <TitleRow minHeight={4.8} maxHeight={4.8} direction="row">
      {topLeftContent}
      {link}
      {topRightContent}
      <IconWrapper
        href={process.env.REACT_APP_MIG_JUMPSTART_HELP_URL}
        target="_blank"
        iconType="help"
      >
        <HelpCircle />
      </IconWrapper>
      <IconWrapper
        href="#"
        iconType="close"
        onClick={(e) => {
          e.preventDefault();
          host?.close();
        }}
      >
        <XCircle /> 
      </IconWrapper>
    </TitleRow>
    <Borderline />
    <TitleRow minHeight={8.6} maxHeight={8.6} direction="row">
      {bottomLeftContent}
      <Clickable className="text" onClick={onClick}><h1>Data Import</h1></Clickable>
      {bottomRightContent}      
    </TitleRow>
  </TitleWrapper>
);
};

export { Title };
